<template>
  <div>
    <b-row>
      <!-- Total Amount -->
      <b-col cols="12" md="2">
        <b-card
          title="Total Amount"
          sub-title="INCLUDING processing fee"
        >
          <table
            v-for="item in totalAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Net Income -->
      <b-col cols="12" md="2">
        <b-card
          title="Net Income"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in netIncome"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Blance -->
      <b-col cols="12" md="2">
        <b-card
          title="Balance"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in balance"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Settlement Amount -->
      <b-col v-if="settlementAmount" cols="12" md="2">
        <b-card
          title="Settlement"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in settlementAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Payout Amount -->
      <b-col v-if="payoutAmount" cols="12" md="2">
        <b-card
          title="Payout"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in payoutAmount"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Frozen -->
      <b-col cols="12" md="2">
        <b-card
          title="Frozen"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in frozen"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <!-- Reserve Fund -->
      <b-col cols="12" md="2">
        <b-card
          title="Reserve"
          sub-title="EXCLUDING processing fee"
        >
          <table
            v-for="item in reserve"
            :key="item.key"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ getJsonKey(item) }}</span>
              </th>
              <td class="pb-50 flex-column text-right">
                {{ getJsonValue(item) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <app-collapse class="mb-2">

      <app-collapse-item v-if="$can('write', 'Merchants.List')" class="mb-2" title="Update Assets">
        <b-card>
          <b-row>
            <b-col>
              <v-select
                v-model="modifiedCurrency"
                input-id="target-currency"
                :options="sourceCurrency"
                :clearable="false"
                :searchable="false"
                top
                placeholder="Currency"
              />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.total" placeholder="Total" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.netIncome" placeholder="Net Income" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.balance" placeholder="Balance" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.settled" placeholder="Settled" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.payout" placeholder="Payout" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.frozen" placeholder="Frozen" type="number" trim />
            </b-col>
            <b-col>
              <b-form-input v-model="modified.reserve" placeholder="Reserve" type="number" trim />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12" md="3">
              <b-form-input v-model="verificationCode" placeholder="Verification Code" trim />
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                :disabled="!modifiedCurrency || modifiedCurrency === '' || verificationCode === ''"
                @click="modifyAssetDataManually"
              >
                Confirm
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item>

      <!-- <app-collapse-item v-if="$can('write', 'Merchants.List')" class="mb-2" title="Reserve Fund">
        <b-card>
          <b-row>
            <b-col>
              <v-select
                v-model="modifiedCurrency"
                input-id="target-currency"
                :options="currencyOptions"
                :clearable="false"
                top
              />
            </b-col>
            <b-col>
              <v-select
                v-model="reserveType"
                input-id="reserve-type"
                :options="reserveTypeOptions"
                :clearable="false"
                :searchable="false"
                :reduce="val => val.value"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <b-button
                variant="primary"
                :disabled="!modifiedCurrency || modifiedCurrency === ''"
                @click="modifyAssetDataManually"
              >
                Confirm
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </app-collapse-item> -->

      <app-collapse-item title="Activity Timeline">
        <app-timeline
          v-for="item in timelineData"
          :key="item.title"
        >
          <app-timeline-item
            :title="`${item.title}`"
            :subtitle="`${item.desc}`"
            :time="`${item.time}`"
            variant="success"
            class="pb-2"
          />
        </app-timeline>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import currency from 'currency.js'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  modifyAssetDataManually,
  fetchCommonOptions,
} = useMerchantsJs()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,

    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      merchantNo: '',

      totalAmount: [],
      netIncome: [],
      balance: [],
      settlementAmount: [],
      payoutAmount: [],
      frozen: [],
      reserve: [],
      timelineData: [],
      sourceCurrency: [],
      currencyOptions: [],
      verificationCode: '',
      modifiedCurrency: '',
      modified: {
        total: '',
        netIncome: '',
        balance: '',
        settled: '',
        payout: '',
        frozen: '',
        reserve: '',
      },
      reserveType: 0,
      reserveTypeOptions: [
        { label: 'Off', value: 0 },
        { label: 'Rolling', value: 1 },
        { label: 'Capacity', value: 2 },
        { label: 'One-Off', value: 3 },
      ],
    }
  },
  watch: {
    data: {
      handler(assets) {
        if (!assets) {
          return
        }
        this.merchantNo = assets.merchantNo
        this.timelineData = assets.history
        this.totalAmount = assets.total_amount
        this.netIncome = assets.net_income
        this.balance = assets.balance
        this.settlementAmount = assets.settlement_amount ? assets.settlement_amount : null
        this.payoutAmount = assets.payout ? assets.payout : null
        this.frozen = assets.frozen ? assets.frozen : null
        this.reserve = assets.reserve ? assets.reserve : null
        this.sourceCurrency = assets.currency_list
      },
      deep: true,
    },
  },
  created() {
    fetchCommonOptions(
      options => {
        const { currencies } = options
        currencies.forEach(item => {
          this.currencyOptions.push(item.code)
        })
      },
      fail => {
        showToast(this, 'Warning', `Fetch options fail with ${fail}`)
      },
    )
  },
  methods: {
    getJsonKey(json) {
      const [keyName] = Object.keys(json)
      return keyName
    },
    getJsonValue(json) {
      const keyName = Object.keys(json)
      return currency(json[keyName], { separator: ',', symbol: '' }).format()
    },
    modifyAssetDataManually() {
      const assets = {
        merchant_no: this.merchantNo,
        currency: this.modifiedCurrency,
        total_revenue: this.modified.total,
        net_income: this.modified.netIncome,
        balance: this.modified.balance,
        settled: this.modified.settled,
        payout: this.modified.payout,
        frozen: this.modified.frozen,
        reserve: this.modified.reserve,
      }
      const params = {
        verificationCode: this.verificationCode,
        assets,
      }
      modifyAssetDataManually(
        params,
        () => {
          showToast(this, 'Success', 'Modified.')
          this.resetAssetDataManually()
        },
        fail => {
          showToast(this, 'Warning', `Modify manually fail with ${fail}.`)
        },
      )
    },
    resetAssetDataManually() {
      this.modifiedCurrency = ''
      this.modified = {
        total: '',
        netIncome: '',
        balance: '',
        settled: '',
        payout: '',
        frozen: '',
        reserve: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
